<!-- 修改密码组件 -->
<template>
  <div class="changPass">
    <h1 class="mui-title">{{$t('changePassPage.changePassTitle')}}</h1>
    <div class="change-content">
      <el-row class="table-grid-content">
        <el-col :span="2" class="grid" :gutter="15">
          <i class="el-icon-arrow-left" style="cursor: pointer;font-size:25px" @click="lastStep"></i>
        </el-col>
      </el-row>
    </div>
    <!--引入element插件HTML样式-->
    <el-form class="user-account-key" ref="form" :model="datas" :rules="rules" label-width="180px">
      <el-form-item :label="$t('changePassPage.oldPassword')" prop="password" autofocu="true">
        <el-input type="password" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('changePassPage.oldPassword')" v-model="datas.password" show-password></el-input>
      </el-form-item>
      <el-form-item :label="$t('changePassPage.newPassword')" prop="phonenumber">
        <el-input type="password" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('changePassPage.newPassword')" v-model="datas.phonenumber" show-password></el-input>
      </el-form-item>
      <el-form-item :label="$t('changePassPage.confirmPassword')" prop="newPhonenumber">
        <el-input
          type="password"
          :placeholder="$t('changePassPage.confirmPassword_ph')"
          v-model="datas.newPhonenumber"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item style="text-align:center">
        <el-button type="primary" @click="onSubmit('form')">{{$t('commonInfo.dialogBtnModify')}}</el-button>
        <el-button @click="$refs['form'].resetFields()">{{$t('commonInfo.dialogBtnReset')}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { Toast } from "mint-ui";
import { MessageBox } from "mint-ui";
export default {
  data() {
    let params = window.sessionStorage.getItem("params");
    //将JSON字符串转换成对象
    let oParams = JSON.parse(params);

    let validatePassword = (rule, value, callback) => {
      if (value != oParams.password) {
        callback(new Error(this.$t('tipsInfo.errorPassword')));
      } else {
        callback();
      }
    };

    //此处即表单发送之前验证
    let validateNewPassword = (rule, value, callback) => {
      console.log(oParams.password);

      if (value == oParams.password) {
        callback(new Error(this.$t('changePassPage.sameOldAndNewPasswordTips')));
      } else {
        callback();
      }
    };
    let validateNewPassword2 = (rule, value, callback) => {
      if (value !== this.datas.phonenumber) {
        callback(new Error(this.$t('changePassPage.diffNewPasswordTips')));
      } else {
        callback();
      }
    };
    return {
      form: {},
      rules: {
        password: [
          { required: true, message: this.$t('tipsInfo.inputPlaceholder') + this.$t('changePassPage.oldPassword'), trigger: "blur" },
          { validator: validatePassword, trigger: "blur" }
        ],
        phonenumber: [
          { required: true, message: this.$t('tipsInfo.inputPlaceholder') + this.$t('changePassPage.newPassword'), trigger: "blur" },
          { validator: validateNewPassword, trigger: "blur" },
          {
            min: 5,
            max: 16,
            message: this.$t('changePassPage.newPasswordLimit'),
            trigger: "blur"
          }
        ],
        newPhonenumber: [
          { required: true, message: this.$t('changePassPage.confirmPassword_ph'), trigger: "blur" },
          { validator: validateNewPassword2, trigger: "blur" }
        ]
      },
      // 表单校验
      datas: {
        password: "",
        phonenumber: "",
        newPhonenumber: ""
      }
    };
  },
  created() {
    // this.getlist();
  },
  methods: {
    lastStep() {
      this.$message.warning(this.$t('changePassPage.cancelAndReload'));
      return this.$router.push({
        path: "/login"
      });
    },
    // 提交表单
    async onSubmit() {
      let params = window.sessionStorage.getItem("params");
      //将JSON字符串转换成对象
      let oParams = JSON.parse(params);
      // console.log(oParams);
      // console.log(oParams.username);

      if (
        this.datas.password == "" ||
        this.datas.phonenumber == "" ||
        this.newPhonenumber == ""
      ) {
        return this.$message.error(this.$t('changePassPage.nullPasswordTips'));
      }

      const oData = await this.$http.put("/api/system/user/changePwd", {
        userName: oParams.username,
        password: oParams.password,
        phonenumber: this.datas.phonenumber
      });
      if (oData.code == 200) {
        this.$message.success(oData.msg);
        return this.$router.push({
          path: "/login"
        });
      } else {
        this.$message.error(oData.msg);
      }
    }
    //获取用户信息
    // async getlist() {
    //   const res = await this.$http.get("/toborder/customer");
    //   if (res.code === 200) {
    //     console.log(res);
    //     for (let i = 0; i < res.data.list.length; i++) {
    //       console.log(res.data.list[i].creator);

    //       this.userName = res.data.list[i].creator;
    //     }
    //   } else {
    //     if (res.code === 401) {
    //       this.$router.push({
    //         path: "/login"
    //       });
    //     }
    //   }
    // }
  }
};
</script>
<style scoped>
.change-back {
  position: absolute;
  top: 24%;
  left: 0%;
}

.change-content {
  position: absolute;
  top: 40%;
  left: 40%;
}

.change-zhanghao {
  position: relative;
  margin-top: 51px;
  margin-left: 15px;
}

.zhanghao {
  margin-bottom: 0;
}

.user-account-key {
  width: 88%;
}
.changPass {
  width: 500px;
  padding: 20px;
  background: #efefef;
  margin: 0 auto;
  margin-top: 100px;
  border-radius: 8px;
}
.mui-title {
  text-align: center;
}
.change-content {
  position: relative;
  top: -55px;
  left: 10px;
}
</style>